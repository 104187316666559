import jwt_decode from "jwt-decode";

import { FormattedMessage } from "react-intl";
const initialState = {
  isPageLoading: false,
  showAlert: false,
  alertMessage: "",
  alertType: "success",
  token: sessionStorage.getItem("token"),
  isAuthenticated: sessionStorage.getItem("isAuthenticated"),
  loginAttempt: sessionStorage.getItem("loginAttempt"),
  isLoading: false,
  serviceUnavailable: false,
  forgotPasswordLoading: false,
  consentDocument: [],
  user:
    sessionStorage.getItem("user") !== undefined &&
    JSON.parse(sessionStorage.getItem("user")),
  host: sessionStorage.getItem("host"),
  profile_pic: sessionStorage.getItem("profile_pic"),
  // company_logo: sessionStorage.getItem("company_logo"),
  loggedUserData:
    sessionStorage.getItem("loggedUserData") !== undefined &&
    JSON.parse(sessionStorage.getItem("loggedUserData")),
  questionList: [],
  registeredUserData: {},
  loginLoading: false,
};

const AuthReducer = function (state = initialState, action) {
  switch (action.type) {
    case "SHOW_PAGE_LOADER":
      return {
        ...state,
        isPageLoading: true,
      };
    case "HIDE_PAGE_LOADER":
      return {
        ...state,
        isPageLoading: false,
      };
    case "SHOW_ALERT":
      return {
        ...state,
        showAlert: true,
        alertType: action.payload.status ? "success" : "error",
        alertMessage:
          typeof action.payload?.message === "string" ? (
            action.payload?.message
          ) : (
            <FormattedMessage id="global.error.msg" />
          ),
      };
    case "HIDE_ALERT":
      return {
        ...state,
        showAlert: false,
      };

    case "USER_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "LOGIN_LOADING": {
      return {
        ...state,
        loginLoading: action.payload,
      };
    }
    case "FORGOT_PASSWORD_LOADING":
      return {
        ...state,
        forgotPasswordLoading: action.payload,
      };
    case "LOGIN_SUCCESS":
      var decoded = jwt_decode(action.payload.token.access);
      sessionStorage.setItem("token", action.payload.token.token);
      sessionStorage.setItem("isAuthenticated", true);
      const consent = decoded?.user_data?.consents?.consent_status ?? false;
      sessionStorage.setItem("consent", consent);
      sessionStorage.setItem(
        "loginAttempt",
        action.payload.business_detail_exist
      );
      sessionStorage.setItem("user", JSON.stringify(decoded.user_data));
      sessionStorage.setItem("host", decoded.user_data.chat_host);
      sessionStorage.setItem("loggedUserData", JSON.stringify(decoded));
      sessionStorage.setItem(
        "profile_pic",
        decoded.user_data.profile_pic?.document
      );
      // sessionStorage.setItem("company_logo", action.payload.party_logo);
      return {
        ...state,
        isAuthenticated: sessionStorage.getItem("isAuthenticated"),
        loginAttempt: sessionStorage.getItem("loginAttempt"),
        token: sessionStorage.getItem("token"),
        user: JSON.parse(sessionStorage.getItem("user")),
        loggedUserData: JSON.parse(sessionStorage.getItem("loggedUserData")),
        profile_pic: sessionStorage.getItem("profile_pic"),
        // company_logo: sessionStorage.getItem("company_logo"),
      };
    case "UPDATE_PROFILE_SUCCESS":
      sessionStorage.setItem("user", JSON.stringify(action.payload.data));

      return {
        ...state,
        user: JSON.parse(sessionStorage.getItem("user")),
      };
    case "UPDATE_PASSWORD_SUCCESS":
      return state;

    case "AUTH_ERROR":
    case "LOGIN_FAIL":
    case "LOGOUT_SUCCESS":
    case "REGISTER_FAIL":
      sessionStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        loggedUserData: {},
        isAuthenticated: false,
        loginAttempt: false,
        isLoading: false,
      };

    case "QUESTION_LIST":
      return {
        ...state,
        questionList: action.payload.data,
      };

    case "GET_CONSENT_DOCUMENT":
      return {
        ...state,
        consentDocument: action.payload.data,
      };
    case "REGISTERED_USER_DATA":
      return {
        ...state,
        registeredUserData: action.payload.data,
      };

    case "IS_SERVICE_UNAVAILABLE":
      return {
        ...state,
        serviceUnavailable: action.payload,
      };

    default:
      return state;
  }
};

export default AuthReducer;
