import {
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import PdfViewer from "./PdfViewer";
import { useEffect, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PdfContainer = ({
  allDocuments,
  fromPolicy,
  reference,
  loading,
  fromMta,
}) => {
  const categories = {
    Policy: "Your policy Summary and Schedule",
    Quote: "Breakdown of your Quote",
  };

  const getName = (item) => {
    return categories[item?.category] ?? item?.name ?? item?.document?.name;
  };

  const docData = allDocuments
    ?.filter(
      (item) =>
        item?.document_category != "policy_billing" &&
        item?.document_category != "policy_addendums"
    )
    ?.map((item) => ({
      id: item?.id,
      name: getName(item),
      url: item?.document ?? item?.document?.document,
      category: item?.category ?? item?.document?.category,
    }));

  const docsWithSections = allDocuments
    ?.filter(
      (item) =>
        item?.document_category == "policy_billing" ||
        item?.document_category == "policy_addendums"
    )
    ?.map((item) => ({
      id: item?.id,
      name: getName(item),
      url: item?.document ?? item?.document?.document,
      category: item?.category ?? item?.document?.category,
      category_folder: item?.category_folder ?? item?.document?.category_folder,
    }));

  const [selectedId, setSelectedId] = useState(docData?.[0]?.id);

  useEffect(() => {
    setSelectedId(docData?.[0]?.id);
  }, [docData?.[0]?.id]);

  const fallBackDoc = "https://docs";
  const firstDoc = docData?.[0]?.url;
  const firstName = docData?.[0]?.name ?? docData?.[0]?.document?.name;
  const [docUrl, setDocUrl] = useState(firstDoc ?? fallBackDoc);
  const history = useHistory();
  useEffect(() => {
    setDocUrl(firstDoc ?? fallBackDoc);
    setDocName(firstName);
  }, [firstDoc]);

  const [docName, setDocName] = useState(firstName);

  const handleSelect = ({ doc }) => {
    setSelectedId(doc?.id);
    setDocUrl(doc?.url ?? fallBackDoc);
    setDocName(doc?.name);
  };

  const renderLoader = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress
        size="70px"
        thickness={1}
        sx={{ color: "gray", height: "50px", width: "50px" }}
      />
    </Box>
  );

  return (
    <>
      {(fromMta || fromPolicy) && (
        <IconButton
          onClick={() => history.goBack()}
          sx={{ mb: 1, padding: 0 }}
          color="inherit"
          aria-label="back"
        >
          <ArrowBack />
          <Typography variant="button">Back</Typography>
        </IconButton>
      )}

      <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
        {fromPolicy
          ? `Your Policy : ${reference ?? ""}`
          : fromMta
          ? `${reference ?? ""} - Policy Addendum`
          : `Your Quote : ${reference ?? ""}`}
      </Typography>
      <Box sx={{ display: "flex", mt: 3, width: "100%", gap: 4 }}>
        <Box sx={{ maxWidth: "13rem" }}>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "500" }}>
            Your Documents
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", mt: 2 }}>
            {/* Click on a document below to view it on screen. You can also print
            or download the document at your discretion. */}
            Click on the links below to view and access your documents.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "start",
            }}
          >
            {docData?.map((doc) => {
              const color = doc.id === selectedId && "#9309FE";
              const name =
                typeof doc?.name === "string"
                  ? doc?.name?.replace(/\.[^/.]+$/, "")
                  : "";
              return (
                <Box key={doc.id}>
                  <IconButton
                    onClick={() => handleSelect({ doc })}
                    sx={{
                      display: "flex",
                      padding: 0,
                      margin: "0",
                      gap: 1.5,
                      color,
                    }}
                  >
                    <EastIcon />
                    <Typography
                      sx={{ overflowWrap: "anywhere", textAlign: "left" }}
                      fontWeight={500}
                    >
                      {name}
                    </Typography>
                  </IconButton>
                </Box>
              );
            })}
            {docsWithSections?.length > 0 && (
              <Divider sx={{ width: "100%", borderBottomWidth: "2.5px" }} />
            )}
            {docsWithSections?.map((doc, index) => {
              const color = doc.id === selectedId && "#9309FE";
              const name =
                typeof doc?.name === "string"
                  ? doc?.name?.replace(/\.[^/.]+$/, "")
                  : "";
              return (
                <Box width="100%" key={doc.id}>
                  <Box>
                    <Typography
                      sx={{
                        overflowWrap: "anywhere",
                        textAlign: "left",
                      }}
                      fontWeight={500}
                    >
                      {doc?.category_folder ?? doc?.document?.category_folder}
                    </Typography>
                    <Box mt={2} ml={2}>
                      <IconButton
                        onClick={() => handleSelect({ doc })}
                        sx={{
                          display: "flex",
                          padding: 0,
                          margin: "0",
                          gap: 1.5,
                          color,
                        }}
                      >
                        <EastIcon />
                        <Typography
                          sx={{ overflowWrap: "anywhere", textAlign: "left" }}
                          fontWeight={500}
                        >
                          {name}
                        </Typography>
                      </IconButton>
                    </Box>
                  </Box>
                  {docsWithSections?.length > index + 1 && (
                    <Divider
                      sx={{
                        width: "100%",
                        borderBottomWidth: "2.5px",
                        mt: "10px",
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box sx={{ background: "white" }} height={"80vh"} width="100%">
          {loading ? (
            renderLoader
          ) : (
            <PdfViewer
              url={docUrl?.document ? docUrl?.document : docUrl}
              name={docName?.name ? docName?.name : docName}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default PdfContainer;
