import axios from "axios";
import { getBearerToken } from "./utils/getToken";
export const baseURL = `${process.env.REACT_APP_BASE_URL}`;
export const reactPdfWorkerUrl = process.env.REACT_APP_PDF_JS_WORKER_URL;
export const FAVICON = process.env.REACT_APP_FAVICON;

//  function to handle 401 unauthorized
function createAxiosInstance(url) {
  const instance = axios.create({
    baseURL: `${baseURL}${url}`,
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
  });

  instance.interceptors.request.use((req) => {
    if (!req.headers["Authorization"]) {
      if (getBearerToken()) {
        req.headers["Authorization"] = getBearerToken();
      }
    }
    return req;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

const API = createAxiosInstance("/api/core/v1/s_m/");
export default API;

export const CoreAPI = createAxiosInstance("/api/v1");

//user api without token
export const loginAuth = axios.create({
  baseURL: `${baseURL}/api/v1/customer-portal/authentication/`,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
  },
});

const initialUrl = "/api/v1";

export const portalBaseUrl = createAxiosInstance(
  `${initialUrl}/customer-portal/`
);

export const InsuranceUrl = createAxiosInstance(`${initialUrl}/insurance/`);

export const storageApi = createAxiosInstance(`${initialUrl}/storage/`);

export const userAPI = createAxiosInstance(`${initialUrl}/customer-portal/`);

export const AvailabilityAPI = createAxiosInstance(
  `${initialUrl}/availability/`
);

export const NotificationAPI = createAxiosInstance(
  `${initialUrl}/notifications/`
);

export const BriefcaseAPI = createAxiosInstance("/api/briefcase/v1/");

export const NotesAPI = createAxiosInstance(baseURL);

export const CalendarAPI = createAxiosInstance("/api/event/v1/");

export const ChatAPI = createAxiosInstance(baseURL);

// marketplace elastic Search
export const MarketPlaceAPI = createAxiosInstance("/api/core/v1/search/");

export const InsurecoreAPI = createAxiosInstance(`${initialUrl}/insurecore`);

export const CrmCompanyAPI = createAxiosInstance(`${initialUrl}/core/parties/`);

export const PortalCompanyAPI = createAxiosInstance(
  `${initialUrl}/customer-portal/`
);

export const NewsFeedAPI = createAxiosInstance(
  `${initialUrl}/customer-portal/newsfeed/`
);
