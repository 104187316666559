import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { BR_EMAIL, serverErrorMessage } from "../../../../utils/constants";

const ServiceUnavailable = () => {
  const theme = useTheme();
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <img
          height={100}
          width={100}
          src={"/assets/images/serviceUnavailable.png"}
          alt="crm-icon"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.4rem",
              color: theme.palette?.primary?.main,
              fontWeight: "bold",
              fontFamily: ["stolzl, Georgia", "Georgia"].join(","),
            }}
          >
            Service Unavailable
          </Typography>
        </Box>
        <Typography
          fontFamily={theme.typography?.fontFamily}
          fontSize={"1rem"}
          mt="10px"
        >
          {serverErrorMessage}
        </Typography>
        <div style={styles.contactSection}>
          <Typography
            sx={{
              fontSize: "1rem",
              color: theme.palette?.primary?.main,
              marginBottom: "10px",
              fontFamily: theme.typography?.fontFamily,
            }}
          >
            If you need immediate assistance, reach out to us:
          </Typography>
          <Typography
            fontFamily={theme.typography?.fontFamily}
            style={styles.contactInfo}
          >
            <EmailOutlinedIcon
              sx={{ fontSize: "18px", fill: theme.palette?.secondary?.main }}
            />
            <a
              target="_blank"
              href={`mailto:${BR_EMAIL}`}
              style={styles.link}
              rel="noreferrer"
            >
              {BR_EMAIL}
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
  },
  card: {
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "40px",
    textAlign: "center",
    maxWidth: "800px",
    width: "100%",
  },
  logo: {
    width: "120px",
    marginBottom: "20px",
  },
  icon: {
    width: "100px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "2rem",
    margin: "10px 0",
  },
  message: {
    fontSize: "1.2rem",
    lineHeight: "1.6",
    marginBottom: "30px",
  },
  contactSection: {
    paddingTop: "20px",
  },
  contactText: {
    fontSize: "1rem",
    marginBottom: "10px",
  },
  contactInfo: {
    fontSize: "1rem",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
};

export default ServiceUnavailable;
